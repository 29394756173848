/** @format */

// import { onBackgroundMessage } from "firebase/messaging/sw";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAefAQHFxM2-y8G6PXxIeOoCjIsEf0D5sw',
  authDomain: 'gk-mechti.firebaseapp.com',
  projectId: 'gk-mechti',
  storageBucket: 'gk-mechti.appspot.com',
  messagingSenderId: '929014640021',
  appId: '1:929014640021:web:e95d9d09e028e3cd091a9c',
  measurementId: 'G-4F0WB0GD1P',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const messaging = getMessaging();
export { getToken };
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);

  // navigator.serviceWorker.register('messaging-sw.js');

  // запрашиваем права на показ уведомлений если еще не получили их
  if (!true)
    Notification.requestPermission(function (result) {
      if (result === 'granted') {
        navigator.serviceWorker.ready
          .then(function (registration) {
            payload.notification.data = payload.notification; // параметры уведомления
            registration.showNotification(payload.notification.title, payload.notification);
          })
          .catch(function (error) {
            console.log('ServiceWorker registration failed', error);
          });
      }
    });
  // ...
});
